const { registerBlockType } = wp.blocks;

var wpmsl_block_container = {
	"text-align": 'center'
};

var divStyle = {
	"margin-bottom": "40px",
};

var wpmsl_logo = {
	
	width: "250px"
}
var map_select = {
	width: '100%'
}


registerBlockType(
	'wpmsl/shortcode',
	{

		title: 'MSL Map Form',
		description: 'Block to add Multistore Locator shortcode to the page',
		icon: 'format-aside',
		category: 'common',
		attributes: {
			type: {
				type: 'string',

			}
		},
		edit( props ) {


			var p       = map_forms.forms;
			var options = [];

			options.push( < option value = "" > Please select your form < / option > )

			for (var key in p) {

				if (p.hasOwnProperty( key )) {
					var form_id    = p[key].ID;
					var form_title = p[key].title;
					if (props.attributes.type == form_id) {

						options.push( < option value = {form_id} selected > {form_title} < / option > )

					} else {

						options.push( < option value = {form_id} > {form_title} < / option > )
					}
				}

			}

			var type = props.attributes.type;

			function wpmsl_shortcode_change(e) {
				var form_id = e.target.value;
				props.setAttributes( {type: form_id} );
			}

			return (
			< div style = {wpmsl_block_container} > < div style = {divStyle} > < img style = {wpmsl_logo} src = {'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpCSsVt7x3IDvax08nMzIFWno70alPngmQng&usqp=CAU'} / > < / div > < div > < select style={map_select} onChange = {wpmsl_shortcode_change} > {options} < / select > < / div > < / div >
		);

		},
		save( props ) {
			return null;
		}

	}
);